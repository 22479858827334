<template>
  <div class="page-wrapper white fixed f14">
    <div class="page-info">
      <div class="page-data-wrapper">
        <el-row class="page-top-line">
          <el-col class="item" :sm="12" :md="8">
            <div class="inner">
              <div class="label">状态</div>
              <div class="content">{{ info.approval_status_text }}</div>
            </div>
          </el-col>
          <el-col class="item" :sm="12" :md="8">
            <div class="inner">
              <div class="label">审批步骤</div>
              <div class="content">{{ info.step_status_text }}</div>
            </div>
          </el-col>
          <el-col class="item" :sm="12" :md="8">
            <div class="inner">
              <div class="label">学生姓名</div>
              <div class="content">{{ info.student_name }}</div>
            </div>
          </el-col>
          <el-col class="item" :sm="12" :md="8">
            <div class="inner">
              <div class="label">行政班</div>
              <div class="content">{{ info.class_name }}</div>
            </div>
          </el-col>
          <el-col class="item" :sm="12" :md="8">
            <div class="inner">
              <div class="label">学校</div>
              <div class="content">{{ info.school_name }}</div>
            </div>
          </el-col>
          <el-col class="item" :sm="12" :md="8">
            <div class="inner">
              <div class="label">年级</div>
              <div class="content">{{ info.grade_name }}</div>
            </div>
          </el-col>
          <el-col class="item" :sm="12" :md="8">
            <div class="inner">
              <div class="label">目标任务名称</div>
              <div class="content">{{ info.target_name }}</div>
            </div>
          </el-col>
          <el-col class="item" :sm="12" :md="8">
            <div class="inner">
              <div class="label">上次考试名称</div>
              <div class="content">{{ info.exam_name }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div>
        <div class="page-table-title">考试目标</div>
        <el-table :data="subject" style="width: 100%" size="small" v-loading="loading" :stripe="false">
          <el-table-column prop="subject_name" label="科目"> </el-table-column>
          <el-table-column prop="class_name" label="教学班"> </el-table-column>
          <el-table-column prop="clerk_name" label="教学班老师"> </el-table-column>
          <el-table-column prop="exam_score" label="上次成绩"> </el-table-column>
          <el-table-column prop="score" label="本次期末目标"> </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="page-tool">
      <el-button type="info" @click="$router.back()">返回</el-button>
      <template v-if="info.approval_status === 0">
        <el-button @click="handleClick(1)">通过</el-button>
        <el-button type="primary" @click="handleClick(0)">驳回</el-button>
      </template>
    </div>
  </div>
</template>
<script>
export default {
	_config:{"route":{"path":"examine","meta":{"title":"审批"}}},
  name: 'ExamineVerifyDetails',
  data() {
    return {
      info: {
        approval_status: 1
      },
      subject: [],
      list: [
        { status: '通过', name: '张三', date: '2023.02.02 12:02:12' },
        { status: '驳回', name: '张三', date: '2023.02.02 12:02:12' }
      ],
      loading: false,
      dialogVisible: false
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      const id = this.$route.query.id || ''
      const step_status = this.$route.query.step_status | ''
      this.loading = true
      this.$_axios2
        .get(`/api/student/approve/details?id=${id}&step_status=${step_status}`)
        .then(res => {
          const {
            data: {
              data: { subject, info }
            }
          } = res
          this.loading = false
          if (info) {
            this.info = info
            this.subject = subject
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleClick(type) {
      let buttonText = '立即驳回',
        content = '驳回后，学生将重新填写学习目标',
        tips = '驳回'
      if (type) {
        content = '通过后，将会流转到下一级审核'
        buttonText = '通过'
        tips = '通过'
      }
      const params = {
        id: this.$route.query.id || '',
        step_status: this.$route.query.step_status | '',
        approval_status: type ? 1 : 2
      }
      let that = this
      this.$confirm(content, tips, {
        confirmButtonText: buttonText,
        cancelButtonText: '点错了',
        type: 'warning',
        beforeClose(action, instance, done) {
          if (instance.confirmButtonLoading) {
            return
          }
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '请求中...'
            that.$_axios2
              .get(`/api/student/approve/step`, { params })
              .then(res => {
                const {
                  data: { status }
                } = res
                instance.confirmButtonLoading = false
                instance.confirmButtonText = buttonText
                if (status) return
                that.getDetail()
                done()
              })
              .catch(() => {
                instance.confirmButtonLoading = false
                instance.confirmButtonText = buttonText
              })
          } else {
            done()
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped></style>
